import React, { FC } from 'react';
import { Grid } from '@mui/material';

import { Icon24UtilityNextArrow } from '@care/react-icons';
import Button from '@mui/material/Button';
import Carousel from '@/components/shared/Carousel';
import { AmplitudeLogger } from '@/utilities/analyticsHelper';
import { EventNames } from '@/constants';
import { testimonials } from '../../data/testimonials';
import TestimonialsCarouselItem from '../TestimonialsCarouseltem/index';
import { SxClassProps } from '@/types/global';

const classes: SxClassProps = {
  carouselWrapper: (theme) => ({
    padding: { xs: theme.spacing(0, 0, 4), md: theme.spacing(0, 0, 6) },
    position: 'relative',
    width: '100%',
    maxWidth: '630px',

    '& .slider': {
      overflowY: 'visible',
      height: '100%',
    },
  }),
  carouselArrow: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 12px)',
    width: 24,
    height: 24,
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.care.navy[800],
    cursor: 'pointer',

    '&:nth-of-type(1)': {
      left: 3,
      rotate: '180deg', // flipping the "next" arrow for consistency because the "prev" arrow svg is different
      transformOrigin: 'center 13px',
    },
    '&:nth-of-type(2)': {
      right: { xs: 2, md: 3 },
    },
  }),
};

type ArrowEvent = (clickHandler: () => void, hasNext: boolean, label: string) => React.ReactNode;

interface TestimonialsCarouselProps {
  logger: AmplitudeLogger;
}

const TestimonialsCarousel: FC<TestimonialsCarouselProps> = ({ logger }) => {
  const logCarouselInteraction = () => {
    logger({
      name: EventNames.LINK_INTERACTED,
      data: {
        link_text: 'Testimonial carousel',
      },
    });
  };
  const renderArrow = (direction: 'previous' | 'next'): ArrowEvent => {
    return (clickHandler, shouldRender) => {
      return (
        shouldRender && (
          <Button
            type="button"
            sx={classes.carouselArrow}
            onClick={() => {
              logCarouselInteraction();
              clickHandler();
            }}
            aria-label={`${direction} slide`}>
            <Icon24UtilityNextArrow />
          </Button>
        )
      );
    };
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} md={7} lg={6} sx={classes.carouselWrapper}>
        <Carousel
          shouldShowArrows
          renderArrowPrev={renderArrow('previous')}
          renderArrowNext={renderArrow('next')}
          logEvent={logCarouselInteraction}>
          {testimonials.map((testimonial) => (
            <TestimonialsCarouselItem testimonial={testimonial} key={testimonial.name} />
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default TestimonialsCarousel;
