import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { FAQ } from '../../types/types';

interface FAQCompanyOfferProps {
  faq: FAQ;
}
const FAQCompanyOffer: FC<FAQCompanyOfferProps> = ({ faq }) => {
  const AnswerComponent = faq.answerComponent;

  if (AnswerComponent) {
    return <AnswerComponent />;
  }

  return <Typography variant="body2">{faq.answer}</Typography>;
};

export default FAQCompanyOffer;
