import { Box, Container, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import useResponsive from '@/components/hooks/useResponsive';
import whyCareImage from '@/../public/static/why-care.webp';
import { WHY_CARE_REASONS } from '../../data/whyCareReasons';
import WhyCareItem from '../WhyCareItem/index';
import { SxClassProps } from '@/types/global';

const classes: SxClassProps = {
  wrapper: (theme) => ({
    backgroundColor: theme.palette.care.blue[100],
    paddingTop: 6,
    paddingBottom: { xs: 8, md: 12 },
    backgroundImage: { lg: `url(${whyCareImage.src})` },
    backgroundRepeat: { lg: 'no-repeat' },
    backgroundPosition: { lg: 'calc(100% + 160px) 100%', xl: '100% 100%' },
    backgroundSize: { lg: 'auto 400px' },
  }),
  subContainer: {
    paddingLeft: { lg: 6, xl: 12 },
    paddingRight: { lg: 6, xl: 12 },
  },
  heading: {
    textAlign: { xs: 'center', lg: 'left' },
    marginBottom: 6,
  },
};

interface WhyCareSectionProps {}

const WhyCareSection: FC<WhyCareSectionProps> = () => {
  const { isDesktop, isLargeDesktop } = useResponsive();
  const headingVariant = isDesktop ? 'serifDisplay3' : 'serifHeadline2';

  return (
    <Box component="section" data-testid="WhyCareSection" sx={classes.wrapper}>
      <Container maxWidth="lg">
        <Box component="div" sx={classes.subContainer}>
          <Typography variant={headingVariant} sx={classes.heading}>
            Why employees love their Care benefits
          </Typography>

          <Grid container justifyContent={isLargeDesktop ? 'flex-start' : 'center'}>
            <Grid item xs={12} md={10} lg={8} xl={7}>
              {WHY_CARE_REASONS.map((reason) => (
                <WhyCareItem key={reason.headline} reason={reason} />
              ))}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default WhyCareSection;
