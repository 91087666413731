import React, { JSX, FC } from 'react';
import { Box } from '@mui/material';
import { Carousel as ReactCarousel, CarouselProps } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface ICarouselProps
  extends Partial<
    Omit<CarouselProps, 'showArrows' | 'showStatus' | 'showThumbs' | 'stopOnHover' | 'swipeable'>
  > {
  children: JSX.Element[];
  shouldAutoPlay?: boolean;
  shouldShowArrows?: boolean;
  logEvent?: Function; // function to trigger an already initialized Amplitude event logger on pagination click
}

const Carousel: FC<ICarouselProps> = ({
  children,
  interval = 4000,
  renderArrowNext,
  renderArrowPrev,
  shouldAutoPlay,
  shouldShowArrows = false,
  width,
  logEvent = () => {},
  ...restProps
}) => {
  return (
    <Box
      data-testid="carousel"
      sx={{
        '& .carousel .control-dots': {
          mb: 4,
        },
      }}>
      <ReactCarousel
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={50}
        autoPlay={shouldAutoPlay}
        dynamicHeight={false}
        emulateTouch
        infiniteLoop
        interval={interval}
        renderArrowNext={renderArrowNext}
        renderArrowPrev={renderArrowPrev}
        showArrows={shouldShowArrows}
        showStatus={false}
        showThumbs={false}
        stopOnHover
        swipeable
        width={width}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <Box
                component="li"
                sx={{
                  width: { xs: 16, md: 24 },
                  height: { xs: 5, md: 8 },
                  display: 'inline-block',
                  margin: { xs: '0 4px', md: '0 8px' },
                  borderRadius: 10,
                  cursor: 'pointer',
                }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
                role="option"
                aria-selected="true"
                color={(theme) => ({
                  background: theme.palette.care.navy[800],
                })}
              />
            );
          }
          return (
            <Box
              component="li"
              color={(theme) => ({
                background: theme.palette.care.navy[800],
              })}
              sx={{
                width: { xs: 5, md: 8 },
                height: { xs: 5, md: 8 },
                display: 'inline-block',
                margin: { xs: '0 4px', md: '0 8px' },
                borderRadius: 10,
                cursor: 'pointer',
              }}
              onClick={(e) => {
                logEvent();
                onClickHandler(e);
              }}
              onKeyDown={(e) => {
                logEvent();
                onClickHandler(e);
              }}
              value={index}
              key={index}
              role="option"
              aria-selected="false"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
        {...restProps}>
        {children}
      </ReactCarousel>
    </Box>
  );
};

export default Carousel;
