import PhoneChildCare from '@/../public/static/PhoneChildCare.webp';
import PhoneSeniorCare from '@/../public/static/PhoneSeniorCare.webp';
import PhonePetCare from '@/../public/static/PhonePetCare.webp';
import PhoneSelfCare from '@/../public/static/PhoneSelfCare.webp';
import PhoneMore from '@/../public/static/PhoneMore.webp';
import { Benefit } from '../types/types';

export const BENEFITS: Benefit[] = [
  {
    title: 'Child Care',
    headline: 'Care for kids of all ages',
    subHeadline: 'Employee child care benefits',
    description:
      'We make it easy to find trusted, flexible care for kids at every age and stage with:',

    valueProps: [
      'Subsidized Backup Care for times when school is out, daycare is closed, or your nanny is sick.',
      'Access to our network of hundreds of thousands of background-checked caregivers to find the right one for your family.',
      'Educational support with tutoring for K-12 plus college, as well as college admissions coaching.',
    ],
    poster: {
      src: PhoneChildCare.src,
      alt: 'Child poster',
    },
  },
  {
    title: 'Senior Care',
    headline: 'Your senior care support system',
    subHeadline: 'Employee senior care benefits',
    description:
      'When an older loved one needs help, your Care employee benefits make it easy to find the right options, including:',
    valueProps: [
      "Expert senior Care Specialists to provide guidance and resources to help you navigate a loved one's aging journey.",
      "Assistance when your senior's caregiver is on vacation, your aging parent needs some help after an injury, or a ride to a doctor's appointment.",
      'Access to the leading platform to find experienced adult caregivers, companion care, and other support.',
    ],
    poster: {
      src: PhoneSeniorCare.src,
      alt: 'Senior poster',
    },
  },
  {
    title: 'Pet Care',
    headline: 'Purr-fect pet care',
    subHeadline: 'Employee pet care benefits',
    description:
      "Finding someone to take care of your pet is easy with Care's employee pet care benefits such as:",
    valueProps: [
      'Subsidized pet Backup Care when you need to travel for work, work additional shifts, or your regular pet caregiver is on vacation and you need to work.',
      'Access to our network of background-checked pet sitters, dog walkers and groomers, doggy daycares, and pet boarding.',
    ],
    poster: {
      src: PhonePetCare.src,
      alt: 'Pet poster',
    },
  },
  {
    title: 'Self Care',
    headline: 'Care for you, too',
    subHeadline: 'Employee self-care benefits',
    description:
      "Knowing someone is there for you can make a world of difference. Care's employee self-care benefits can help you find care that fits your specific needs, including:",
    valueProps: [
      'Subsidized care that can come to your home for support during recovery from illness, injury, or surgery.',
      'Access to the leading platform to find background-checked home care, transportation services, and moving support, as well as caregivers for errands, chores, and odd jobs.',
    ],
    poster: {
      src: PhoneSelfCare.src,
      alt: 'Self care poster',
    },
  },
  {
    title: 'More Care',
    headline: 'Find more, with Care',
    subHeadline: 'Additional Care employee benefits',
    description:
      "We've got a wealth of additional employee benefits for you to explore, including:",
    valueProps: [
      'Exclusive offers on child care, travel, fitness centers, and more through LifeMart discounts.',
      'Personalized caregiving support from our team of expert Care Specialists.',
      '24/7, 1:1 breastfeeding support from Board Certified Lactation Consultants.',
    ],
    poster: {
      src: PhoneMore.src,
      alt: 'More care poster',
    },
  },
];
