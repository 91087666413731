import { LandingPageHeader } from '@care/navigation';
import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { AppFooter } from '@care/enterprise-components';
import getConfig from 'next/config';
import CareLogo from '@/components/shared/CareLogo';
import { useEventLogger } from '@/utilities/analyticsHelper';
import { useFlag } from '@/context/FeatureFlagsContext';
import { EventNames, FEATURE_FLAGS } from '@/constants';
import useDirectEnrollmentData from '@/components/hooks/useDirectEnrollmentData';
import BenefitsSection from './components/BenefitsSection/index';
import EnrollmentSection from './components/EnrollmentSection/index';
import WhyCareSection from './components/WhyCareSection/index';
import TrustedSection from './components/TrustedSection/index';
import FAQSection from './components/FAQSection/index';
import TestimonialsSection from './components/TestimonialsSection/index';
import Hero from './components/Hero/index';

const WelcomePage: React.FC = () => {
  const logger = useEventLogger({
    screen_name: 'Universal Landing Page',
    user_flow: 'Universal Landing Page',
  });
  const utmRedirectLdFlagResult = useFlag(FEATURE_FLAGS.ENT_UTM_REDIRECT_FLAG.key);
  const { update } = useDirectEnrollmentData();

  const handleLogin = () => {
    window.location.href = `/vis/auth/login`;
  };
  const {
    publicRuntimeConfig: { CZEN_GENERAL },
  } = getConfig();

  const headerButton = (
    <Button
      color="secondary"
      variant="outlined"
      sx={{
        width: '118px',
      }}
      onClick={handleLogin}>
      Login
    </Button>
  );

  useEffect(() => {
    update({
      utmRedirectFlagEnabled: utmRedirectLdFlagResult,
    });

    logger({
      name: EventNames.SCREEN_VIEWED,
    });
  }, []);

  return (
    <Box component="div" data-testid="UniversalLandingPage">
      <Box
        component="div"
        sx={{
          '& > div:first-of-type': {
            py: { xs: 1, md: 3 },
          },
        }}>
        <LandingPageHeader button={headerButton} logo={<CareLogo variant="secondary" />} />
      </Box>

      {/* Hero */}
      <Hero logger={logger} hideFlowers />

      {/* Care Benefits */}
      <BenefitsSection logger={logger} />

      {/* Why Care */}
      <WhyCareSection />

      {/* Testimonials */}
      <TestimonialsSection logger={logger} />

      {/* Trusted */}
      <TrustedSection />

      {/* Enrollment */}
      <EnrollmentSection logger={logger} />

      {/* FAQ */}
      <FAQSection logger={logger} />

      <AppFooter baseUrl={CZEN_GENERAL} />
    </Box>
  );
};

export default WelcomePage;
