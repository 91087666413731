import React, { FC } from 'react';
import getConfig from 'next/config';

import { Box, Link, Typography } from '@mui/material';
import { EventNames, TEXTS } from '@/constants';

type TermsOfUseVariant = 'universal' | 'abbreviated' | 'full';
interface TermsOfUseProps {
  amplitudeLogger: Function;
  buttonCaption: string;
  flat?: boolean;
  variant?: TermsOfUseVariant;
}

// Constants
const {
  publicRuntimeConfig: { CZEN_GENERAL },
} = getConfig();

type PolicyEvent = EventNames.TERMS_OF_USE_CLICKED | EventNames.PRIVACY_POLICY_CLICKED;

const { TERMS_OF_USE } = TEXTS.STATIC;

/* eslint-disable camelcase */
export const policyClickData: { [key: string]: { name: PolicyEvent; link_text: string } } = {
  terms: {
    name: EventNames.TERMS_OF_USE_CLICKED,
    link_text: 'Terms of Use',
  },
  privacy: {
    name: EventNames.PRIVACY_POLICY_CLICKED,
    link_text: 'Privacy Policy',
  },
};

const TermsOfUse: FC<TermsOfUseProps> = ({
  amplitudeLogger,
  buttonCaption,
  flat = false,
  variant = 'full',
}) => {
  const handlePolicyEvent = (key: string) => () => amplitudeLogger(policyClickData[key]);
  const abbreviatedTOU = (
    <Box component="div" sx={flat ? null : { marginTop: 2, marginBottom: 2 }}>
      <Typography variant="body3" color="textSecondary">
        By choosing {buttonCaption} you agree to our&nbsp;
        <Link
          id="terms-of-use-link"
          underline="always"
          onClick={handlePolicyEvent('terms')}
          href={`${CZEN_GENERAL}/enterprise/terms.do`}
          target="_blank"
          variant="link3"
          rel="noreferrer">
          {policyClickData.terms.link_text}
        </Link>
        {' and '}
        <Link
          id="privacy-policy-link"
          underline="always"
          onClick={handlePolicyEvent('privacy')}
          href={`${CZEN_GENERAL}/about/privacy-policy/`}
          variant="link3"
          target="_blank"
          rel="noreferrer">
          {policyClickData.privacy.link_text}
        </Link>
        .
      </Typography>
    </Box>
  );

  const fullTOU = (
    <>
      {abbreviatedTOU}
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="body3" color="textSecondary">
          {TERMS_OF_USE.CONTENT}
        </Typography>
      </Box>
    </>
  );

  if (variant === 'abbreviated') {
    return abbreviatedTOU;
  }

  if (variant === 'universal') {
    return (
      <Typography variant="disclaimer2" color={(theme) => theme.palette.care.white}>
        By clicking <em>{buttonCaption}</em> you agree to our&nbsp;
        <Link
          id="terms-of-use-link"
          underline="always"
          onClick={handlePolicyEvent('terms')}
          href={`${CZEN_GENERAL}/enterprise/terms.do`}
          target="_blank"
          variant="link4"
          rel="noreferrer"
          sx={(theme) => ({
            color: theme.palette.care.white,

            '&:hover,&:focus,&:focus:active': {
              color: theme.palette.care.white,
            },
          })}>
          {policyClickData.terms.link_text}
        </Link>
        {' and '}
        <Link
          id="privacy-policy-link"
          underline="always"
          onClick={handlePolicyEvent('privacy')}
          href={`${CZEN_GENERAL}/about/privacy-policy/`}
          variant="link4"
          target="_blank"
          rel="noreferrer"
          sx={(theme) => ({
            color: theme.palette.care.white,

            '&:hover,&:focus,&:focus:active': {
              color: theme.palette.care.white,
            },
          })}>
          {policyClickData.privacy.link_text}
        </Link>
        .
      </Typography>
    );
  }

  return fullTOU;
};

export default TermsOfUse;
