import { useMediaQuery, useTheme } from '@mui/material';

function useResponsive() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints?.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints?.only('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints?.up('md'));
  const isLargeDesktop = useMediaQuery(theme.breakpoints?.up('lg'));

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
  };
}

export default useResponsive;
