import { CMS_PREFIX } from '@/constants';

const oldCmsPrefix = 'https://www.care.com//img/cms/web/careAtWork/directEnrollment';

export const WELCOME_IMAGES = {
  FLORAL_LEFT: `${CMS_PREFIX}/Floral_Left_DT@4x-min.png`,
  FLORAL_RIGHT: `${CMS_PREFIX}/Floral_Right_DT@4x-min.png`,
  FLORAL_MW: `${CMS_PREFIX}/Floral_MW@4x-min.png`,
  MOM_AND_CHILD_DT: `${CMS_PREFIX}/photo@2x-min.png`,
  MOM_AND_CHILD_MW: `${oldCmsPrefix}/MomandchildMW.png`,
  FLORAL_CLUSTER_1: `${oldCmsPrefix}/floral_01.png`,
  FLORAL_CLUSTER_2: `${oldCmsPrefix}/Floral_cluster_02_portrait_4.png`,
};
