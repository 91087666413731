import { AccordionLabel, AccordionPanel } from '@care/react-component-lib';
import { Accordion, Box, Container, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import useResponsive from '@/components/hooks/useResponsive';
import { AmplitudeLogger } from '@/utilities/analyticsHelper';
import { EventNames } from '@/constants';
import { FAQS } from '../../data/faqs';
import FAQAnswer from '../FAQAnswer/index';
import { SxClassProps } from '@/types/global';

const classes: SxClassProps = {
  container: {
    paddingTop: 6,
    paddingBottom: 8,
  },
  heading: {
    textAlign: 'center',
    marginBottom: 3,
  },
  accordionContainer: {
    '& .MuiAccordionSummary-root': {
      height: 'auto',
      alignItems: 'flex-start',
      minHeight: 0,
      paddingTop: 2,
      paddingBottom: 2,
      lineHeight: '22px',
    },

    '& .MuiAccordionSummary-content': {
      margin: 0,
    },

    '.MuiAccordionSummary-content > .MuiTypography-body2': {
      fontWeight: 'bold',
    },
  },
};

interface FAQSectionProps {
  logger: AmplitudeLogger;
}

const FAQSection: FC<FAQSectionProps> = ({ logger }) => {
  const { isDesktop } = useResponsive();

  const headingVariant = isDesktop ? 'serifDisplay3' : 'serifHeadline4';

  const accordionClick = (questionText: string) => {
    logger({
      name: EventNames.LINK_INTERACTED,
      data: {
        screen_name: 'Universal Landing Page',
        link_text: questionText,
      },
    });
  };

  return (
    <Container maxWidth="lg" component="section" sx={classes.container}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10} lg={7} xl={6}>
          <Typography variant={headingVariant} sx={classes.heading}>
            Frequently asked questions
          </Typography>

          <Box component="div" sx={classes.accordionContainer}>
            {FAQS.map((faq) => (
              <Accordion
                key={faq.question}
                elevation={0}
                onChange={() => accordionClick(faq.question)}>
                <AccordionLabel primaryLabel={faq.question} />
                <AccordionPanel noPadding>
                  <FAQAnswer faq={faq} />
                </AccordionPanel>
              </Accordion>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FAQSection;
