import React, { FC } from 'react';
import Image, { ImageLoaderProps } from 'next/image';
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { theme as careTheme } from '@care/material-ui-theme';
import { AmplitudeLogger } from '@/utilities/analyticsHelper';
import TermsOfUse from '@/components/shared/TermsOfUse';
import { WELCOME_IMAGES } from '../../data/welcomeImages';
import CompanySearchForm from '../CompanySearchForm/index';
import { ENROLLMENT_CTA_TEXT } from '../../data/text';
import { SxClassProps } from '@/types/global';

interface HeroProps {
  logger: AmplitudeLogger;
  hideFlowers?: boolean;
}

const Hero: FC<HeroProps> = ({ logger, hideFlowers }) => {
  const isMobile = useMediaQuery(careTheme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(careTheme.breakpoints.only('sm'));
  const isLargeDesktop = useMediaQuery(careTheme.breakpoints.up('lg'));

  const floralClusterImg = {
    src: WELCOME_IMAGES.FLORAL_LEFT,
    alt: 'cluster of flowers',
    className: 'flowerLeft',
  };
  const floralImg = {
    src: WELCOME_IMAGES.FLORAL_RIGHT,
    alt: 'flower',
    className: 'flowerRight',
  };
  const momAndChildImg = {
    src: WELCOME_IMAGES.MOM_AND_CHILD_DT,
    alt: 'mother reading with child',
    className: 'heroImage',
  };
  const headerText = `Join to find care when and where you need it`;
  const subHeaderText = `Get access to your free, employer-sponsored account today`;

  const classes: SxClassProps = {
    heroWrapper: (theme) => ({
      backgroundColor: theme.palette.care.grey[50],
      position: 'relative',
    }),

    heroBackground: {
      overflow: 'hidden',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',

      '& .flowerLeft': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: '144px',
        width: '100px',
      },

      '& .flowerRight': {
        position: 'absolute',
        left: { xs: 'unset', md: '6%' },
        right: { xs: 0, md: 'unset' },
        top: 0,
        height: '72px',
        width: '123px',
      },

      '& .heroImageWrapper': {
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100%',
        width: { xs: '100%', md: '40%', lg: '42%' },
      },

      '& .heroImage': {
        paddingBottom: '0',
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100%',
        width: '100%',
      },
    },
  };

  // This is a temporary optimization. By appending the `?im=` parameter, we are getting an optimized image from Akamai.
  // Images optimized in this way are cached on edge servers only after first fetch from each server and stay cached for at least 30 days.
  // TODO - replace with standard loader once we establish one.
  const imageLoader = ({ src }: ImageLoaderProps): string => `${src}?im=`;

  const floralClusterImage = !hideFlowers && isLargeDesktop && (
    <div className={floralClusterImg.className}>
      <Image
        priority
        src={floralClusterImg.src}
        alt={floralClusterImg.alt}
        layout="fill"
        objectFit="contain"
        loader={imageLoader}
        sizes="50vw"
      />
    </div>
  );

  const heroImage = !isMobile && !isTablet && (
    <div className={momAndChildImg.className}>
      <Image
        priority
        src={momAndChildImg.src}
        alt={momAndChildImg.alt}
        layout="fill"
        objectFit="cover"
        objectPosition="left center"
        loader={imageLoader}
        sizes="50vw"
      />
    </div>
  );

  const flowerRightImage = !hideFlowers && (
    <div className={floralImg.className}>
      <Image
        priority
        src={floralImg.src}
        alt={floralImg.alt}
        layout="fill"
        objectFit="contain"
        loader={imageLoader}
        sizes="50vw"
      />
    </div>
  );

  const renderSearchForm = () => (
    <>
      <Typography
        variant={isMobile ? 'h1' : 'display3'}
        className="header"
        sx={{
          marginTop: '0px',
          marginBottom: 2,
        }}>
        {headerText}
      </Typography>
      <Typography
        variant="body1"
        className="subHeader"
        sx={{
          marginTop: '0px',
          marginBottom: 2,
        }}>
        {subHeaderText}
      </Typography>
      <Box component="div" className="form">
        <CompanySearchForm logger={logger} ctaText={ENROLLMENT_CTA_TEXT} />
        <TermsOfUse
          amplitudeLogger={logger}
          buttonCaption={ENROLLMENT_CTA_TEXT}
          variant="abbreviated"
          flat
        />
      </Box>
    </>
  );

  return (
    <Box component="section" sx={classes.heroWrapper}>
      <Box component="div" sx={classes.heroBackground}>
        <div className="heroImageWrapper">
          {heroImage}
          {flowerRightImage}
        </div>
        {floralClusterImage}
      </Box>

      <Container
        sx={{
          zIndex: 1,
          position: 'relative',
          maxWidth: { xs: '456px', md: '1440px' },
          paddingTop: { xs: 3, sm: 6, lg: 10 },
          paddingBottom: { xs: 10, md: 6, lg: 10 },
          px: { xs: 3, md: 4, lg: 10 },
        }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            {renderSearchForm()}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
