import { Link, Typography } from '@mui/material';
import React, { FC } from 'react';

const FAQCompanyOffer: FC = () => {
  return (
    <Typography variant="body2">
      Type your employer&lsquo;s name in the form above. If your employer&lsquo;s name does not
      appear, please reach out to your HR representative or{' '}
      <Link
        href="https://www.care.com/lp/advocate"
        variant="body2"
        target="_blank"
        rel="noreferrer noopener">
        complete this form to advocate for Care.
      </Link>
    </Typography>
  );
};

export default FAQCompanyOffer;
