import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as yup from 'yup';
import { ENTERPRISE_ROUTES, EventNames, FEATURE_FLAGS, TEXTS, memberEnrollment } from '@/constants';
import { DirectEnrollment } from '@/types/directEnrollment';
import { useFlag } from '@/context/FeatureFlagsContext';
import {
  verifyEmployeeExistsByEmail,
  verifyEmployeeExistsByEmailVariables,
  verifyEmployeeExistsByEmail_enterpriseVerifyEmployeeExistsByEmail_EnterpriseVerifyEmployeeExistsByEmailSuccess as EnterpriseVerifyEmployeeExistsByEmailSuccess,
} from '@/__generated__/verifyEmployeeExistsByEmail';
import { AmplitudeLogger } from '@/utilities/analyticsHelper';
import useDirectEnrollmentData from './useDirectEnrollmentData';
import { VERIFY_EMPLOYEE_EXISTS_BY_EMAIL } from '../request/GQL';
import { CompanySearchSelection } from '../pages/WelcomePage/components/CompanySearchInput/CompanySearchInput';

interface ValidationSchema {
  email: yup.StringSchema<string, object>;
  groupShortName: yup.StringSchema<string, object>;
  groupDisplayName: yup.StringSchema<string, object>;
}

export const validationSchema: yup.ObjectSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  groupShortName: yup.string().required('Employer name is required'),
  groupDisplayName: yup.string().required('Employer name is required'),
} as ValidationSchema);

const useCompanySearchForm = (logger: AmplitudeLogger) => {
  const router = useRouter();
  const { fetch, update } = useDirectEnrollmentData();
  const excludeClients = useFlag(FEATURE_FLAGS.ENT_REDIRECT_LD_FLAG.key);
  const lifeCareTransitionD2EClients = useFlag(
    FEATURE_FLAGS.ENT_LIFECARE_TRANSITION_D2E_CLIENT_FLAG.key
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [ssoConnectionPath, setSSOConnectionPath] = useState<string | null>('');
  const [memberExists, setMemberExists] = useState<boolean>(false);
  const [apolloError, setApolloError] = useState<string>('');

  const [verifyEmployeeExists, { data: verifyEmployeeData, error: verifyEmployeeError, loading }] =
    useLazyQuery<verifyEmployeeExistsByEmail, verifyEmployeeExistsByEmailVariables>(
      VERIFY_EMPLOYEE_EXISTS_BY_EMAIL,
      { context: { skipAuth: true } }
    );

  const storageValue = fetch();
  const companySearchPreFillName =
    (router?.query?.groupName as string) || storageValue?.groupDisplayName || '';
  const shouldAutoFocusEmail = Boolean(companySearchPreFillName) && !storageValue.email;

  const handleFormSubmit = async (values: Partial<DirectEnrollment>) => {
    setIsSubmitting(true);
    const { groupShortName, groupDisplayName, email } = values;
    update({ groupShortName, groupDisplayName, email });

    if (Boolean(excludeClients) && groupShortName! in excludeClients) {
      window.location.href = `${window.location.protocol}//${window.location.host}/${groupShortName}`;
      return;
    }

    if (email && groupShortName) {
      verifyEmployeeExists({
        variables: {
          input: {
            email,
            groupShortName,
          },
        },
      });
    }
  };

  const handleSelection = async ({ name, shortName }: CompanySearchSelection) => {
    formik.setFieldValue('groupShortName', shortName);
    formik.setFieldValue('groupDisplayName', name);
    if (name.length >= 2) {
      await formik.setFieldTouched('groupShortName', true, true);
      await formik.setFieldTouched('groupDisplayName', true, true);
    }
  };

  const handleCompanySearchError = (errorMessage: string): void => {
    formik.setFieldError('groupShortName', errorMessage);
    formik.setFieldTouched('groupShortName', true, false);
  };

  const formik = useFormik<Partial<DirectEnrollment>>({
    initialValues: {
      groupShortName: storageValue.groupShortName,
      groupDisplayName: storageValue.groupDisplayName,
      email: storageValue.email,
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: handleFormSubmit,
  });

  const handleVerifyEmployeeSuccess = (
    successData: EnterpriseVerifyEmployeeExistsByEmailSuccess
  ) => {
    if (successData.memberExists) {
      logger({
        name: EventNames.ERROR_VIEWED,
        data: {
          message: TEXTS.ERRORS.EMAIL_ALREADY_EXISTS,
          step_name: 'Welcome',
          step_number: '0',
          wps_group: formik.values.groupShortName,
        },
      });

      setMemberExists(successData.memberExists);
      return;
    }

    if (ssoConnectionPath) {
      window.location.href = ssoConnectionPath;
    } else if (
      Boolean(lifeCareTransitionD2EClients) &&
      storageValue.groupShortName in lifeCareTransitionD2EClients
    ) {
      router.push(
        `${memberEnrollment}${ENTERPRISE_ROUTES.LIFECARE_AUTHENTICATION}?groupShortName=${storageValue.groupShortName}`
      );
    } else {
      router.push(`${memberEnrollment}${ENTERPRISE_ROUTES.PERSONAL_INFO}`);
    }
  };

  useEffect(() => {
    if (verifyEmployeeData) {
      const { enterpriseVerifyEmployeeExistsByEmail } = verifyEmployeeData;

      if (
        enterpriseVerifyEmployeeExistsByEmail.__typename ===
        'EnterpriseVerifyEmployeeExistsByEmailSuccess'
      ) {
        handleVerifyEmployeeSuccess(enterpriseVerifyEmployeeExistsByEmail);
      }

      if (enterpriseVerifyEmployeeExistsByEmail.__typename === 'EnterpriseFailureResponse') {
        setApolloError(enterpriseVerifyEmployeeExistsByEmail.knownErrors[0].message);
      }

      setIsSubmitting(false);
    }

    if (verifyEmployeeError) {
      setIsSubmitting(false);
      setApolloError(TEXTS.ERRORS.UNEXPECTED_ERROR);
    }
  }, [verifyEmployeeData, verifyEmployeeError]);

  return {
    formik,
    memberExists,
    companySearchPreFillName,
    shouldAutoFocusEmail,
    apolloError,
    loading,
    isSubmitting,
    setSSOConnectionPath,
    handleSelection,
    handleCompanySearchError,
  };
};

export default useCompanySearchForm;
