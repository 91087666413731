import { Icon24InfoResponseTime, Icon48BrandBgcCriminal } from '@care/react-icons';
import { WhyCareReason } from '../types/types';
import IconStars from '../components/IconStars/index';

export const WHY_CARE_REASONS: WhyCareReason[] = [
  {
    headline: 'The leading brand for Care',
    description:
      "Millions of families trust Care to find caregivers that fit their specific needs for children, seniors, pets, home, tutors, and more. That's why employers from Fortune 500 companies to small businesses count on Care to provide caregiving support to their employees.",
    icon: IconStars,
  },
  {
    headline: 'Safety is our top priority',
    description:
      'Care sets the standard for safety among online care platforms. 100% of caregivers interacting with families have been background-checked with CareCheck, we provide tips and tools to help you have safe experiences, and stand ready to support you if you need it.',
    icon: Icon48BrandBgcCriminal,
  },
  {
    headline: 'Get more done at work and at home',
    description:
      'Navigating caregiving challenges can be time-consuming and stressful. We help you to excel at work and thrive at home. 76% of employees said that their productivity has increased with Care benefits.',
    icon: Icon24InfoResponseTime,
  },
];
