import React, { FC, useRef } from 'react';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { Form, FormikProvider } from 'formik';
import { ErrorNotification } from '@care/enterprise-components';
import useCompanySearchForm from '@/components/hooks/useCompanySearchForm';
import CompanySearchInput, {
  CompanySearchSelection,
} from '@/components/pages/WelcomePage/components/CompanySearchInput/CompanySearchInput';
import { EventNames, LoginLocation, TEXTS } from '@/constants';
import { AmplitudeLogger } from '@/utilities/analyticsHelper';
import TermsOfUse from '@/components/shared/TermsOfUse';
import FormikInlineTextField from '@/components/shared/blocks/FormikInlineTextField';
import { SxClassProps } from '@/types/global';

const CTA_TEXT = 'Enroll now';

const classes: SxClassProps = {
  fields: {
    marginBottom: 1,

    '& > .input-container': {
      paddingTop: 0,
      paddingBottom: 0,
    },

    '& .MuiFormControl-root': {
      padding: 0,
      marginTop: 2,
    },
  },
  buttonContainer: {
    textAlign: 'center',
  },
  helperText: (theme) => ({
    '&.MuiFormHelperText-root': {
      color: theme.palette.care.white,
    },
    '&.Mui-error': {
      color: theme.palette.care.red[400],
    },
  }),
  helperLink: (theme) => ({
    fontWeight: 'bold',
    color: theme.palette.care.white,
    '&:hover,&:focus,&:focus:active': {
      color: theme.palette.care.white,
    },
  }),
  cta: {
    width: '100%',
    maxWidth: { sm: '327px' },
  },
  ctaSecondary: {
    width: '100%',
    maxWidth: '233px',
  },
};

interface EnrollmentFormProps {
  id: string;
  logger: AmplitudeLogger;
  variant?: 'primary' | 'secondary';
  disableAutoFocus?: boolean;
  ctaTextLocation: string;
  ctaText?: string;
}

const EnrollmentForm: FC<EnrollmentFormProps> = ({
  id,
  logger,
  variant = 'primary',
  disableAutoFocus,
  ctaText = CTA_TEXT,
  ctaTextLocation,
}) => {
  const emailRef = useRef<HTMLInputElement>();

  const headingMargin = variant === 'primary' ? 3 : 4;
  const headingMarginMd = variant === 'primary' ? 2 : 5;

  const buttonContainerClass = variant === 'primary' ? undefined : classes.buttonContainer;
  const buttonClass = variant === 'primary' ? classes.cta : classes.ctaSecondary;

  const {
    formik,
    memberExists,
    apolloError,
    loading,
    companySearchPreFillName,
    shouldAutoFocusEmail,
    handleCompanySearchError,
    handleSelection,
    setSSOConnectionPath,
  } = useCompanySearchForm(logger);

  const handleLogin = () => {
    logger({
      name: EventNames.LOGIN,
      data: {
        login_location: LoginLocation.WELCOME_PAGE_EMAIL_ERROR,
      },
    });
    window.location.href = `/vis/auth/login`;
  };

  const handleCtaClick = () => {
    logger({
      name: EventNames.CTA_INTERACTED,
      data: {
        cta_location: ctaTextLocation,
        cta_text: ctaText,
      },
    });

    if (Object.keys(formik.errors).length) {
      logger({
        name: EventNames.ERROR_VIEWED,
        data: {
          error_type: 'Form Validation Error',
          message: Object.values(formik.errors)[0],
        },
      });
    }
  };

  const handleCompanySelect = (selection: CompanySearchSelection, isPreFetch?: boolean) => {
    handleSelection(selection);

    // focus on email input
    if (selection.name && !isPreFetch) {
      emailRef.current?.focus();
    }
  };

  const helpText = memberExists && (
    <Typography variant="info1" sx={classes.helperText}>
      {TEXTS.ERRORS.EMAIL_ALREADY_EXISTS}
      <Link onClick={handleLogin} variant="link4" sx={classes.helperLink}>{` Log in?`}</Link>
    </Typography>
  );

  return (
    <div>
      <FormikProvider value={formik}>
        <Form id={id}>
          <ErrorNotification text={apolloError} />

          <Box component="div" sx={{ marginBottom: { xs: headingMargin, md: headingMarginMd } }}>
            <Grid container sx={classes.fields} spacing={2} justifyContent="center">
              <Grid
                item
                xs={12}
                sm={variant === 'primary' ? 6 : 10}
                md={6}
                className="input-container">
                <CompanySearchInput
                  inputProps={{
                    id: `${id}_company`,
                  }}
                  helperTextProps={{
                    sx: classes.helperText,
                  }}
                  onSelection={handleCompanySelect}
                  onError={handleCompanySearchError}
                  setSSOConnectionPath={setSSOConnectionPath}
                  autoFocus={!disableAutoFocus && !shouldAutoFocusEmail}
                  prefillName={companySearchPreFillName}
                  preFetch={Boolean(companySearchPreFillName)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={variant === 'primary' ? 6 : 10}
                md={6}
                className="input-container">
                <FormikInlineTextField
                  inputProps={{ ref: emailRef }}
                  id={`${id}_email`}
                  name="email"
                  label="Email"
                  autoFocus={!disableAutoFocus && shouldAutoFocusEmail}
                  helperText={helpText}
                  FormHelperTextProps={{ sx: classes.helperText }}
                />
              </Grid>
            </Grid>

            {variant === 'secondary' && (
              <TermsOfUse amplitudeLogger={logger} buttonCaption={ctaText} variant="universal" />
            )}
          </Box>

          <Box component="div" sx={buttonContainerClass}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleCtaClick}
              disabled={loading}
              sx={buttonClass}>
              {ctaText}
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default EnrollmentForm;
