/* eslint-disable camelcase */
import {
  Icon48BrandBenefitBucks,
  Icon48BrandCat,
  Icon48BrandChildcare,
  Icon48BrandSeniorCare,
  Icon48BrandSpecialNeeds,
} from '@care/react-icons';
import OP_avatar from '@/../public/static/testimonials/OP_avatar.png';
import CS_avatar from '@/../public/static/testimonials/CS_avatar.png';
import JM_avatar from '@/../public/static/testimonials/JM_avatar.png';
import PM_avatar from '@/../public/static/testimonials/PM_avatar.png';
import LF_avatar from '@/../public/static/testimonials/LF_avatar.png';
import { Testimonial } from '../types/types';

export const testimonials: Testimonial[] = [
  {
    quote: `"Backup Care has been incredibly beneficial to our family! My husband and I both work full-time and Backup Care has been a huge stress reliever for us. Our toddler absolutely loves the caregivers and asks for them by name all the time."`,
    name: 'O.P.',
    description: 'Care benefits user',
    img: OP_avatar.src,
    icon: Icon48BrandChildcare,
  },
  {
    quote: `"Please accept my sincere and immense thanks for making calls and gathering information to help us make an informed decision about the best agency for my mom. It has taken a load off of my shoulders and I'm very grateful!"`,
    name: 'C.S.',
    description: 'Care benefits user',
    img: CS_avatar.src,
    icon: Icon48BrandSeniorCare,
  },
  {
    quote: `"When I travel for work, it's important to know my cat is taken care of. If he gets lonely, he refuses to eat. Pet Backup Care gives me access to a "friend" to check in on him while I'm gone. He eats, he plays, he's happy ... I'm happy!"`,
    name: 'J.M.',
    description: 'Care benefits user',
    img: JM_avatar.src,
    icon: Icon48BrandCat,
  },
  {
    quote: `"Very easy to explain your needs and get matches that have background checks, DMV records, and references. I was able to find help in less than 48 hours. What a resource for single parents. I'd give it a 10 if I could."`,
    name: 'P.M.',
    description: 'Care benefits user',
    img: PM_avatar.src,
    icon: Icon48BrandSpecialNeeds,
  },
  {
    quote: `"I saved $300! I planned a family vacation with LifeMart and saved $300 in theme park admission, hotel, and minivan rentals. It also has local deals—try it out!"`,
    name: 'L.F.',
    description: 'Care benefits user',
    img: LF_avatar.src,
    icon: Icon48BrandBenefitBucks,
  },
];
