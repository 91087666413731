import FAQCompanyOffer from '../components/FAQCompanyOffer/index';
import { FAQ } from '../types/types';

export const FAQS: FAQ[] = [
  {
    question: 'How do I know if my company offers benefits through Care?',
    answerComponent: FAQCompanyOffer,
  },
  {
    question: "How do I know if I'm eligible to enroll?",
    answer:
      'Eligibility requirements vary by employer. You may begin the enrollment process, but if you see an error message, please check with your HR representative to see if you are eligible. ',
  },
  {
    question: 'What information do I need to enroll?',
    answer:
      'Generally, you will need to provide your email address, first name, last name, birth date, and address. Some employers require your work email or employee ID to confirm eligibility, so it is a good idea to have those ready if you are prompted.',
  },
  {
    question: 'What if I already have a Care account?',
    answer:
      'If you have already used Care in the past and want to enroll in your benefits, please proceed through the enrollment process above with the same email as your current account. You will be prompted to merge your accounts, select yes to use the same login. If your employer requires you to use a work email, you may need to open a new account using that email address.',
  },
  {
    question: 'How do I know what benefits my employer provides?',
    answer:
      'Please enroll or log in to your Care account to see your entire benefits offering on the Member Home Page. You may also contact your HR representative for a full list of Care benefits available to you.',
  },
  {
    question: 'Do my benefits ever expire or change?',
    answer:
      'Your employer may change your Care benefits over time. Some benefits, like Backup Care allocations, may expire and refresh on an annual basis. Please check your Member Home Page for any updates and your current benefits or contact your HR representative.',
  },
  {
    question:
      "What happens if I'm no longer employed by my company or if my company no longer offers benefits through Care?",
    answer:
      'When you leave an employer or your employer decides not to renew Care benefits, your access to all Care benefits ends immediately. You may still access the Care platform for other services.',
  },
];
