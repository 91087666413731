import { Tabs, Tab, Box } from '@mui/material';
import React, { FC, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { AmplitudeLogger } from '@/utilities/analyticsHelper';
import { EventNames } from '@/constants';
import BenefitDetails from '../BenefitDetails/index';
import { Benefit } from '../../types/types';
import { SxClassProps } from '@/types/global';

const classes: SxClassProps = {
  tabs: (theme) => ({
    '& .Mui-selected': {
      color: theme.palette.care.red[500],
    },
    '& .MuiTab-root:focus': {
      borderBottomColor: theme.palette.care.red[500],
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.care.red[500],
    },
  }),
  tabsContainer: (theme) => ({
    '& .transition-group': {
      position: 'relative',
      overflow: 'hidden',
      minHeight: '504px',

      '& .tab-content-exit': {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        width: '100%',
      },

      '@media (prefers-reduced-motion: no-preference)': {
        '& .tab-content-enter': {
          opacity: 0,

          '& .poster-animator': {
            '@keyframes slideIn': {
              '0%': {
                opacity: 0,
                transform: 'translateX(-150px)',
              },
              '100%': {
                opacity: 1,
                transform: 'translateX(0)',
              },
            },
            animation: `slideIn 350ms ${theme.transitions.easing.easeInOut}`,
          },
        },
        '& .tab-content-enter-active': {
          opacity: 1,
          transition: 'opacity 350ms',
        },
        '& .tab-content-exit': {
          opacity: 1,

          '& .poster-animator': {
            '@keyframes slideOut': {
              '0%': {
                opacity: 1,
                transform: 'translateX(0)',
              },
              '100%': {
                opacity: 0,
                transform: 'translateX(150px)',
              },
            },
            animation: `slideOut 350ms ${theme.transitions.easing.easeInOut}`,
          },
        },
        '& .tab-content-exit-active': {
          opacity: 0,
          transition: 'opacity 350ms',
        },
      },
    },
  }),
};

interface BenefitsSectionProps {
  benefits: Benefit[];
  logger: AmplitudeLogger;
}

const BenefitsSection: FC<BenefitsSectionProps> = ({ logger, benefits }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabSelect = (newValue: number) => {
    const selectedBenefit = benefits[newValue];

    if (selectedBenefit) {
      logger({
        name: EventNames.LINK_INTERACTED,
        data: {
          link_text: `${selectedBenefit.title} clicked`,
        },
      });
    }

    setSelectedTab(newValue);
  };

  return (
    <div data-testid="BenefitsTabs">
      <Tabs
        onChange={(_event, newValue) => tabSelect(newValue)}
        value={selectedTab}
        variant="fullWidth"
        indicatorColor="primary"
        sx={classes.tabs}>
        {benefits.map((tab, index) => (
          <Tab key={tab.title} id={index.toString()} label={tab.title} />
        ))}
      </Tabs>
      <Box sx={classes.tabsContainer}>
        <TransitionGroup className="transition-group">
          <>
            {benefits.map((benefit, index) => (
              <CSSTransition
                in={selectedTab === index}
                key={benefit.title}
                timeout={350}
                unmountOnExit
                classNames="tab-content">
                <BenefitDetails benefit={benefit} renderDesktopView />
              </CSSTransition>
            ))}
          </>
        </TransitionGroup>
      </Box>
    </div>
  );
};

export default BenefitsSection;
