import React, { FC } from 'react';
import { Card } from '@care/react-component-lib';
import { Box, Grid, Typography } from '@mui/material';
import { Testimonial } from '../../types/types';
import { SxClassProps } from '@/types/global';

const classes: SxClassProps = {
  cardWrapper: (theme) => ({
    padding: theme.spacing(4, 1.25, 1.25),
    height: '100%',
    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,.15))',
    paddingTop: { md: '36px' },
  }),
  card: {
    height: '100%',
    boxShadow: 'none',
    position: 'relative',
    overflow: 'visible',
  },
  testimonialContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  testimonial: (theme) => ({
    padding: { xs: theme.spacing(6, 6), sm: theme.spacing(6, 10) },
    textAlign: 'left',
  }),
  iconWrapper: (theme) => ({
    width: '56px',
    height: '56px',
    border: `2px solid ${theme.palette.grey[100]}`,
    borderRadius: '50%',
    position: 'absolute',
    left: '50%',
    top: { xs: '-28px', md: '-32px' },
    transform: 'translateX(-50%)',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  quote: {
    marginBottom: 2,
  },
  img: {
    height: '48px',
    minWidth: '48px',
    maxWidth: '48px',
    borderRadius: '50%',
    marginRight: 1,
  },
  tertiaryText: (theme) => ({
    color: theme.palette.care.grey[600],
  }),
};

interface TestimonialsCarouselItemProps {
  testimonial: Testimonial;
}

const TestimonialsCarouselItem: FC<TestimonialsCarouselItemProps> = ({
  testimonial: { icon, quote, img, description, name },
}) => {
  const iconComponent = () => {
    const IconComponent = icon;
    return <IconComponent size={48} />;
  };

  return (
    <Box component="div" sx={classes.cardWrapper}>
      <Card sx={classes.card}>
        <Box component="div" sx={classes.testimonialContainer}>
          <Box component="div" sx={classes.iconWrapper}>
            {iconComponent()}
          </Box>
          <Box component="div" sx={classes.testimonial}>
            <Box component="div" sx={classes.quote}>
              <Typography>{quote}</Typography>
            </Box>
            <Grid container>
              <Grid item>
                <Box component="img" src={img} alt={description} sx={classes.img} />
              </Grid>
              <Grid item>
                <strong>{name}</strong>
                <Typography variant="body2" sx={classes.tertiaryText}>
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default TestimonialsCarouselItem;
