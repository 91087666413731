import React, { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { AmplitudeLogger } from '@/utilities/analyticsHelper';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import TestimonialsCarousel from '../TestimonialsCarousel/index';
import { SxClassProps } from '@/types/global';

const classes: SxClassProps = {
  container: {
    margin: '0 auto',
    paddingTop: { xs: 7, md: 8 },
    paddingBottom: { xs: 12, md: 13 },
  },
  heading: (theme) => ({
    textAlign: 'center',
    marginBottom: 4,
    fontSize: { md: theme.typography.serifDisplay3.fontSize },
    lineHeight: { md: theme.typography.serifDisplay3.lineHeight },
  }),
};

interface TestimonialsSectionProps {
  logger: AmplitudeLogger;
}

const TestimonialsSection: FC<TestimonialsSectionProps> = ({ logger }) => {
  return (
    <Box
      component="section"
      color={(theme) => ({
        backgroundColor: theme.palette.care.white,
      })}>
      <Container sx={classes.container}>
        <Typography variant="serifHeadline2" component="h2" sx={classes.heading}>
          In their own words
        </Typography>
        <TestimonialsCarousel logger={logger} />
      </Container>
    </Box>
  );
};

export default TestimonialsSection;
