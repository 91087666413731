import React, { FC, useState } from 'react';
import { FormikProvider, useFormik, Form } from 'formik';
import * as yup from 'yup';
import { useRouter } from 'next/router';
import { useLazyQuery } from '@apollo/client';
import { Grid, Button, Typography, Link } from '@mui/material';
import { ErrorNotification } from '@care/enterprise-components';
import { AmplitudeLogger } from '@/utilities/analyticsHelper';
import {
  ENTERPRISE_ROUTES,
  EventNames,
  FEATURE_FLAGS,
  LoginLocation,
  memberEnrollment,
  TEXTS,
} from '@/constants';
import CompanySearchInput, {
  CompanySearchSelection,
} from '@/components/pages/WelcomePage/components/CompanySearchInput/CompanySearchInput';
import useDirectEnrollmentData from '@/components/hooks/useDirectEnrollmentData';
import { DirectEnrollment } from '@/types/directEnrollment';
import {
  verifyEmployeeExistsByEmailVariables,
  verifyEmployeeExistsByEmail,
} from '@/__generated__/verifyEmployeeExistsByEmail';
import { VERIFY_EMPLOYEE_EXISTS_BY_EMAIL } from '@/components/request/GQL';
import { useFlag } from '@/context/FeatureFlagsContext';
import OverlaySpinner from '@/components/shared/OverlaySpinner';
import FormikInlineTextField from '@/components/shared/blocks/FormikInlineTextField';
import { SxClassProps } from '@/types/global';

type CompanySearchFormVariant = 'control' | 'universal';
export interface CompanySearchFormProps {
  logger: AmplitudeLogger;
  ctaText: string;
  variant?: CompanySearchFormVariant;
}

interface ValidationSchema {
  email: yup.StringSchema<string, object>;
  groupShortName: yup.StringSchema<string, object>;
  groupDisplayName: yup.StringSchema<string, object>;
}

export const validationSchema: yup.ObjectSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  groupShortName: yup.string().required('Employer name is required'),
  groupDisplayName: yup.string().required('Employer name is required'),
} as ValidationSchema);

const classes: SxClassProps = {
  fields: {
    marginBottom: 3,
    marginTop: 2,
    width: 'calc(100% + 24px)',

    '& > .input-container': {
      paddingTop: 0,
      paddingBottom: 0,
      minWidth: { md: '280px' },
      maxWidth: { md: '408px' },
      flexGrow: { md: 1 },
    },

    '& .MuiFormControl-root': {
      padding: 0,
    },
  },
  fieldsUniversal: {
    marginBottom: 3,
    marginTop: 2,
    '& > .input-container': {
      paddingTop: 0,
      paddingBottom: 0,
    },

    '& .MuiFormControl-root': {
      padding: 0,
    },
  },
  button: {
    display: 'block',
    width: '100%',
    maxWidth: '327px',
    margin: `0 auto 24px`,
    marginLeft: { md: 0 },
    marginRight: { md: 0 },
    marginBottom: 2,
  },
  buttonUniversal: {
    display: 'block',
    width: '100%',
    marginBottom: 2,
    maxWidth: { md: '300px' },
  },
};

const CompanySearchForm: FC<CompanySearchFormProps> = ({
  logger,
  ctaText,
  variant = 'control',
}) => {
  const router = useRouter();
  const { fetch, update } = useDirectEnrollmentData();
  const storageValue = fetch();
  const excludeClients = useFlag(FEATURE_FLAGS.ENT_REDIRECT_LD_FLAG.key);
  const lifeCareTransitionD2EClients = useFlag(
    FEATURE_FLAGS.ENT_LIFECARE_TRANSITION_D2E_CLIENT_FLAG.key
  );
  const [memberExists, setMemberExists] = useState<boolean>(false);
  const [apolloError, setApolloError] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [ssoConnectionPath, setSSOConnectionPath] = useState<string | null>('');
  const [verifyEmployeeExists, { loading }] = useLazyQuery<
    verifyEmployeeExistsByEmail,
    verifyEmployeeExistsByEmailVariables
  >(VERIFY_EMPLOYEE_EXISTS_BY_EMAIL, {
    context: { skipAuth: true },
    onCompleted: (verifyEmployeeData) => {
      const { enterpriseVerifyEmployeeExistsByEmail } = verifyEmployeeData;
      if (
        enterpriseVerifyEmployeeExistsByEmail.__typename ===
        'EnterpriseVerifyEmployeeExistsByEmailSuccess'
      ) {
        const alreadyExists = enterpriseVerifyEmployeeExistsByEmail.memberExists;
        if (!alreadyExists) {
          if (ssoConnectionPath) {
            window.location.href = ssoConnectionPath;
            return;
          }
          if (
            Boolean(lifeCareTransitionD2EClients) &&
            storageValue.groupShortName in lifeCareTransitionD2EClients
          ) {
            router.push(
              `${memberEnrollment}${ENTERPRISE_ROUTES.LIFECARE_AUTHENTICATION}?groupShortName=${storageValue.groupShortName}`
            );
            return;
          }
          router.push(`${memberEnrollment}${ENTERPRISE_ROUTES.PERSONAL_INFO}`);
          return;
        }
        if (alreadyExists) {
          logger({
            name: EventNames.ERROR_VIEWED,
            data: {
              message: TEXTS.ERRORS.EMAIL_ALREADY_EXISTS,
              step_name: 'Welcome',
              step_number: '0',
              wps_group: formik.values.groupShortName,
            },
          });
        }
        setMemberExists(enterpriseVerifyEmployeeExistsByEmail.memberExists);
      }
      if (enterpriseVerifyEmployeeExistsByEmail.__typename === 'EnterpriseFailureResponse') {
        setApolloError(enterpriseVerifyEmployeeExistsByEmail.knownErrors[0].message);
      }
      setIsSubmitting(false);
    },
    onError: () => {
      setIsSubmitting(false);
      setApolloError(TEXTS.ERRORS.UNEXPECTED_ERROR);
    },
  });

  const buttonClass = variant === 'universal' ? classes.buttonUniversal : classes.button;
  const fieldsClass = variant === 'universal' ? classes.fieldsUniversal : classes.fields;

  const handleFormSubmit = async (values: Partial<DirectEnrollment>) => {
    setIsSubmitting(true);
    const { groupShortName, groupDisplayName, email } = values;
    update({ groupShortName, groupDisplayName, email });

    if (Boolean(excludeClients) && groupShortName! in excludeClients) {
      window.location.href = `${window.location.protocol}//${window.location.host}/${groupShortName}`;
      return;
    }

    if (email && groupShortName) {
      verifyEmployeeExists({
        variables: {
          input: {
            email,
            groupShortName,
          },
        },
      });
    }
  };
  const formik = useFormik<Partial<DirectEnrollment>>({
    initialValues: {
      groupShortName: storageValue.groupShortName,
      groupDisplayName: storageValue.groupDisplayName,
      email: storageValue.email,
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: handleFormSubmit,
  });

  const handleSelection = async ({ name, shortName }: CompanySearchSelection) => {
    formik.setFieldValue('groupShortName', shortName);
    formik.setFieldValue('groupDisplayName', name);
    if (name.length >= 2) {
      await formik.setFieldTouched('groupShortName', true, true);
      await formik.setFieldTouched('groupDisplayName', true, true);
    }
  };

  const handleCtaClick = () => {
    logger({
      name: EventNames.CTA_INTERACTED,
      data: {
        cta_text: ctaText,
        user_email: formik.values.email,
        wps_group: formik.values.groupShortName,
        lifecare_transition_client: isLifecareTransitionClient().toString(),
        sso_client: Boolean(ssoConnectionPath).toString(),
      },
    });
  };

  const isLifecareTransitionClient = () => {
    return formik.values.groupShortName
      ? formik.values.groupShortName in lifeCareTransitionD2EClients
      : false;
  };

  const handleLogin = () => {
    logger({
      name: EventNames.LOGIN,
      data: {
        login_location: LoginLocation.WELCOME_PAGE_EMAIL_ERROR,
      },
    });
    window.location.href = `/vis/auth/login`;
  };

  const handleCompanySearchError = (errorMessage: string): void => {
    formik.setFieldError('groupShortName', errorMessage);
    formik.setFieldTouched('groupShortName', true, false);
  };

  const helpText = memberExists && (
    <Typography color="error" variant="info1">
      {TEXTS.ERRORS.EMAIL_ALREADY_EXISTS}
      <Link onClick={handleLogin} variant="link4" sx={{ fontWeight: 'bold' }}>{` Log in?`}</Link>
    </Typography>
  );

  const companySearchPreFillName =
    (router?.query?.groupName as string) || storageValue?.groupDisplayName || '';

  const shouldAutoFocusEmail = Boolean(companySearchPreFillName) && !storageValue.email;

  return (
    <>
      <OverlaySpinner isTransparent isOpen={isSubmitting} />

      <FormikProvider value={formik}>
        <Form id="primary_enrollment_form">
          <ErrorNotification text={apolloError} />
          <Grid container sx={fieldsClass} spacing={2}>
            <Grid
              item
              xs={12}
              sm={variant === 'universal' ? 6 : 12}
              md={6}
              className="input-container">
              <CompanySearchInput
                onSelection={handleSelection}
                onError={handleCompanySearchError}
                setSSOConnectionPath={setSSOConnectionPath}
                autoFocus={!shouldAutoFocusEmail}
                prefillName={companySearchPreFillName}
                preFetch={Boolean(router?.query?.groupName)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={variant === 'universal' ? 6 : 12}
              md={6}
              className="input-container">
              <FormikInlineTextField
                autoFocus={shouldAutoFocusEmail}
                id="email"
                name="email"
                label="Email"
                helperText={helpText}
                sx={{ marginTop: 2 }}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            onClick={handleCtaClick}
            disabled={loading}
            sx={buttonClass}>
            {ctaText}
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
};

export default CompanySearchForm;
