import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { WhyCareReason } from '../../types/types';
import { SxClassProps } from '@/types/global';

const classes: SxClassProps = {
  heading: {
    textAlign: { xs: 'center', md: 'left' },
    marginBottom: 2,
  },
  article: {
    display: { md: 'flex' },

    '&:not(:last-of-type)': {
      marginBottom: 8,
    },
    marginLeft: { md: 7, lg: null },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 2,
    marginRight: { md: 2 },
  },
};

interface WhyCareSectionProps {
  reason: WhyCareReason;
}

const WhyCareSection: FC<WhyCareSectionProps> = ({ reason }) => {
  const IconComponent = reason.icon;

  return (
    <Box component="article" sx={classes.article}>
      <Box component="div" sx={classes.iconContainer}>
        <IconComponent size={48} />
      </Box>
      <div>
        <Typography variant="h2" component="h4" sx={classes.heading}>
          {reason.headline}
        </Typography>
        <Typography variant="body2">{reason.description}</Typography>
      </div>
    </Box>
  );
};

export default WhyCareSection;
