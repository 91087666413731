/* eslint-disable camelcase */
import React, {
  ChangeEvent,
  useState,
  useRef,
  KeyboardEvent,
  useEffect,
  Ref,
  useCallback,
} from 'react';
import { FormHelperTextProps, InputProps, debounce, Box, List, ListItem } from '@mui/material';
import { InlineTextField } from '@care/react-component-lib';
import { TEXTS } from '@/constants';
import { findCareGroupsByPartialName } from '@/__generated__/findCareGroupsByPartialName';
import capitalize from '@/utilities/capitalize';
import { useFindCareGroupName } from '../../../../hooks/useFindCareGroupName';
import { SxClassProps } from '@/types/global';

const {
  ERRORS: { EMPLOYER_NAME_INVALID, SERVER_ERROR_GENERIC },
} = TEXTS;

const numResults: number = 10;
const minChars: number = 2;
const maxHeight: string = '170px';
const classes: SxClassProps = {
  lookup: (theme) => ({
    position: 'relative',
    cursor: 'pointer',
    '& .suggestions': {
      position: 'absolute',
      top: 9,
      boxShadow: `0px 4px 16px rgba(0, 0, 0, 0.15)`,
      marginLeft: 1,
      border: theme.palette.care?.grey[200],
      borderRadius: '12px',
      zIndex: '3',
      background: theme.palette.care?.white,
      width: '94%',
      padding: 0,
      maxHeight,
      overflowY: 'auto',
    },
    '& li': {
      listStyle: 'none',
      padding: 1,
      textTransform: 'capitalize',
      cursor: 'pointer',
      '&.highlight': {
        background: theme.palette.care?.grey[100],
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  }),
};

export interface CompanySearchProps {
  onSelection({ name, shortName }: CompanySearchSelection, isPreFetch?: boolean): void;
  onError(errorMessage: string): void;
  setSSOConnectionPath(ssoConnectionPath: string | null): void;
  autoFocus?: boolean;
  prefillName?: string;
  preFetch?: boolean;
  inputProps?: InputProps;
  helperTextProps?: FormHelperTextProps;
}

export type Suggestion = {
  name: string;
  shortName: string | null;
  ssodirectLoginEnabled: boolean | null;
  ssoConnectionPath: string | null;
};

export interface CompanySearchSelection extends Suggestion {
  shortName: string;
}

export interface FormattedSuggestion extends CompanySearchSelection {
  highlighted: boolean;
}

// This is only used for reference when a selection is made that updates the userInput. If input matches what was selected, we don't want to query again.
let selectedName = '';

const CompanySearchInput = ({
  onSelection,
  onError,
  setSSOConnectionPath,
  autoFocus = false,
  prefillName = '',
  preFetch = false,
  inputProps,
  helperTextProps,
}: CompanySearchProps) => {
  const suggestionsRef: Ref<HTMLUListElement> = useRef(null);
  const [memoizedSuggestions, setMemoizedSuggestions] = useState<{
    [key: string]: FormattedSuggestion[];
  }>({});
  const groupInputRef = useRef<HTMLInputElement>();
  const [initialLoad, setInitialLoad] = useState(true);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<string>(prefillName);
  const [highlightedSuggestion, setHighlightedSuggestion] = useState<number>(0);
  const [formattedSuggestions, setFormattedSuggestions] = useState<FormattedSuggestion[]>([]);
  const isMinChars = (): boolean => userInput?.length >= minChars;
  const isPreFetching = initialLoad && preFetch && prefillName;

  const resetSelection = (): void => {
    onSelection({ name: '', shortName: '', ssodirectLoginEnabled: false, ssoConnectionPath: '' });
    selectedName = '';
  };

  const onGetSuggestionsCompleted = (data: findCareGroupsByPartialName): void => {
    // Filter out nulls and repeated values, and exclude objects without the "name" property
    const newFormattedSuggestions = (data.findCareGroupsByPartialName || [])
      .filter(({ shortName, name }) => shortName !== null && name !== undefined)
      .filter(
        (group, index, arr) => arr.findIndex((g) => g.shortName === group.shortName) === index
      )
      .map(({ shortName, ssoConnectionPath, ssodirectLoginEnabled, name }) => ({
        shortName,
        ssoConnectionPath,
        ssodirectLoginEnabled,
        name: capitalize(name),
        highlighted: false,
      })) as FormattedSuggestion[];

    setFormattedSuggestions(newFormattedSuggestions);

    const autoSelection = newFormattedSuggestions.filter(
      ({ name }) => name.toLowerCase().trim() === userInput.toLowerCase().trim()
    )[0];
    if (autoSelection && !isFocused) {
      handleSuggestionSelect(autoSelection);
    } else if (isPreFetching) {
      setInitialLoad(false);
      setUserInput('');
      resetSelection();
    }
  };

  const { getSuggestions, data: findCareGroupData, error: serverError } = useFindCareGroupName();

  const handleOnChange = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
    setUserInput(value);
  };

  const handleSuggestionSelect = ({
    name,
    shortName,
    ssodirectLoginEnabled,
    ssoConnectionPath,
  }: FormattedSuggestion) => {
    setUserInput(name);
    onSelection(
      { name, shortName, ssodirectLoginEnabled, ssoConnectionPath },
      Boolean(isPreFetching)
    );
    selectedName = name;
    setInitialLoad(false);
    setSSOConnectionPath(ssodirectLoginEnabled ? ssoConnectionPath : '');
    blurInput();
  };

  const handleAutoSelect = () => {
    if (!selectedName) {
      const validOption = formattedSuggestions.filter(
        ({ name }) => name.toLowerCase().trim() === userInput.toLowerCase().trim()
      )[0];
      if (validOption) {
        handleSuggestionSelect(validOption);
      }
    }
  };

  const handleOnBlur = () => {
    setIsFocused(false);

    // Race-condition occurs when clicking on a suggestion from the suggestion list due to the onBlue event firing in the same event cycle.
    // Wrapping in a setTimeout allows the previous event cycle to compelete before running handling auto select and error display.
    setTimeout(() => {
      if (!serverError && !selectedName && userInput) {
        onError(EMPLOYER_NAME_INVALID);
      }

      handleAutoSelect();
    }, 0);
  };

  const isSelectedOnlyOption = () => {
    return (
      formattedSuggestions.length === 1 &&
      formattedSuggestions[0].name === selectedName &&
      selectedName === userInput
    );
  };

  useEffect(() => {
    setShowSuggestions(
      Boolean(isFocused && isMinChars() && formattedSuggestions.length) && !isSelectedOnlyOption()
    );
  }, [isFocused, formattedSuggestions, userInput, selectedName]);

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleHighlightAtIndex = (index: number) => {
    if (suggestionsRef?.current) {
      setHighlightedSuggestion(index);
      Array.from(suggestionsRef?.current.getElementsByTagName('li')).forEach(
        (li: HTMLLIElement, i: number) => {
          if (index === i) {
            li.classList.add('highlight');
            li.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          } else li.classList.remove('highlight');
        }
      );
    }
  };

  const debouncedGetSuggestions = useCallback(
    debounce((input) => {
      getSuggestions({ variables: { partialName: input, numResults } });
    }, 200),
    [getSuggestions, numResults]
  );

  useEffect(() => {
    const lowerUserInput = userInput.toLowerCase();

    // Reset selection when input changes, unless the change was a result of a chosen selection
    if (selectedName && selectedName.toLowerCase() !== lowerUserInput) {
      resetSelection();
    }

    // Never show suggestions if min character constraint not met or if a selection is already made
    if (!selectedName && isMinChars()) {
      // Use memoized suggestions if they exist
      const memoizedSuggestion = memoizedSuggestions[lowerUserInput];
      if (memoizedSuggestion) {
        setFormattedSuggestions(memoizedSuggestion);
        setInitialLoad(false);
      } else {
        // Debounced getSuggestions call
        debouncedGetSuggestions(lowerUserInput);
      }
    }
  }, [userInput]);

  useEffect(() => {
    if (findCareGroupData) {
      onGetSuggestionsCompleted(findCareGroupData);
    }
  }, [findCareGroupData]);

  useEffect(() => {
    // Memoize suggestions the first time we get valid unique input
    if (!memoizedSuggestions[userInput.toLowerCase()]) {
      setMemoizedSuggestions((prevValues) => ({
        ...prevValues,
        [userInput.toLowerCase()]: formattedSuggestions,
      }));
    }
  }, [formattedSuggestions]);

  useEffect(() => {
    if (serverError) onError(SERVER_ERROR_GENERIC);
  }, [serverError]);

  const suggestionList = () => {
    if (!showSuggestions) {
      return null;
    }

    return (
      <List
        className="suggestions"
        data-testid="suggestions"
        ref={suggestionsRef}
        sx={{ marginTop: 9 }}>
        {formattedSuggestions.map((suggestion, i) => (
          /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
          /* eslint-disable jsx-a11y/click-events-have-key-events */
          /* eslint-disable jsx-a11y/mouse-events-have-key-events */
          <ListItem
            key={suggestion?.name}
            className={i === 0 ? 'highlight' : ''}
            onClick={() => handleSuggestionSelect(suggestion)}
            onMouseDown={(e) => e.preventDefault()} // prevent input blur before click event
            onMouseOver={() => handleHighlightAtIndex(i)}>
            {suggestion?.name}
          </ListItem>
        ))}
      </List>
    );
  };

  const blurInput = () => {
    groupInputRef.current?.blur();
  };

  const handleKeyboardInput = (e: KeyboardEvent) => {
    const length = formattedSuggestions?.length;
    let nextIndex = highlightedSuggestion;
    if (showSuggestions) {
      switch (e.nativeEvent.key) {
        case `ArrowDown`:
          e.preventDefault(); // prevent moving the cursor inside the input while selecting options
          nextIndex = (highlightedSuggestion + 1) % length;
          handleHighlightAtIndex(nextIndex);
          break;
        case `ArrowUp`:
          e.preventDefault(); // prevent moving the cursor inside the input while selecting options
          nextIndex = (highlightedSuggestion ? highlightedSuggestion - 1 : length - 1) % length;
          handleHighlightAtIndex(nextIndex);
          break;
        case `Enter`:
          if (formattedSuggestions[highlightedSuggestion]) {
            handleSuggestionSelect(formattedSuggestions[highlightedSuggestion]);
          }
          break;
        case `Escape`:
          blurInput();
          break;
        default:
          return;
      }
      setHighlightedSuggestion(nextIndex);
    }
  };

  return (
    <Box component="div" sx={classes.lookup}>
      <InlineTextField
        inputProps={{ ref: groupInputRef }}
        autoFocus={autoFocus}
        id={inputProps?.id || 'groupShortName'}
        name="groupShortName"
        label="Employer name"
        autoComplete="off"
        value={userInput}
        onKeyDown={handleKeyboardInput}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        FormHelperTextProps={helperTextProps}
        sx={{ marginTop: 2 }}
      />
      {suggestionList()}
    </Box>
  );
};

export default CompanySearchInput;
