import React, { FC } from 'react';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import useResponsive from '@/components/hooks/useResponsive';
import { AmplitudeLogger } from '@/utilities/analyticsHelper';
import EnrollmentForm from '../EnrollmentForm/index';
import WaveDivider, { POSITION } from '../WaveDivider/WaveDivider';
import { SxClassProps } from '@/types/global';

const classes: SxClassProps = {
  wrapper: (theme) => ({
    backgroundColor: theme.palette.care.navy[800],
  }),
  container: {
    paddingTop: { xs: 6, md: 8 },
    paddingBottom: { xs: 8, md: 10 },
  },
  headline: (theme) => ({
    textAlign: 'center',
    marginBottom: { xs: 3, md: 4 },
    paddingLeft: 3,
    paddingRight: 3,
    color: theme.palette.care.white,
    fontSize: { md: theme.typography.serifDisplay3.fontSize },
    lineHeight: { md: theme.typography.serifDisplay3.lineHeight },
  }),
};

interface EnrollmentSectionProps {
  logger: AmplitudeLogger;
}

const EnrollmentSection: FC<EnrollmentSectionProps> = ({ logger }) => {
  const { isMobile, isTablet } = useResponsive();
  const careTheme = useTheme();
  return (
    <Box component="section" sx={classes.wrapper}>
      <WaveDivider
        position={POSITION.ABSOLUTE}
        backgroundColor="transparent"
        shapeFill={careTheme.palette.care.navy[800]}
        flipHorizontal={isMobile || isTablet}
      />

      <Container maxWidth="lg" sx={classes.container}>
        <Typography variant="serifHeadline3" component="h2" sx={classes.headline}>
          Ready to enroll in your Care benefits?
        </Typography>

        <Grid container justifyContent="center">
          <Grid item xs={12} sm={9} md={8} lg={6}>
            <EnrollmentForm
              id="secondary_enrollment_form"
              logger={logger}
              variant="secondary"
              disableAutoFocus
              ctaText="Join now"
              ctaTextLocation="Additional enrollment"
            />
          </Grid>
        </Grid>
      </Container>

      <WaveDivider
        position={POSITION.ABSOLUTE}
        backgroundColor="transparent"
        shapeFill={careTheme.palette.care.white}
        flipHorizontal={isMobile || isTablet}
      />
    </Box>
  );
};

export default EnrollmentSection;
