import React from 'react';
import { Box } from '@mui/material';
import useResponsive from '@/components/hooks/useResponsive';
import { SxClassProps } from '@/types/global';

export enum POSITION {
  RELATIVE = 'relative',
  ABSOLUTE = 'absolute',
}

interface StyleProps {
  height?: number;
  position?: POSITION;
  shapeFill?: string;
  backgroundColor?: string;
  flipVertical?: boolean;
  flipHorizontal?: boolean;
}

const transform = (flipHorizontal: boolean | undefined, flipVertical: boolean | undefined) => {
  if (flipHorizontal) {
    return 'scaleX(-1)';
  }

  return flipVertical ? 'rotateX(180deg)' : 'inherit';
};

type WaveDividerProps = {
  position?: POSITION;
  shapeFill?: string;
  backgroundColor?: string;
  flipVertical?: boolean;
  flipHorizontal?: boolean;
};

function WaveDivider({
  position,
  shapeFill,
  backgroundColor,
  flipVertical,
  flipHorizontal,
}: WaveDividerProps) {
  const { isMobile, isTablet } = useResponsive();

  const width = '100%';
  const height = isMobile || isTablet ? 27 : 40;

  const classes: SxClassProps = {
    waveContainer: {
      position: 'relative',
      width: '100%',
    },
    wave: {
      bottom: '-1px',
      position,
      width: '100%',
      height: `${height}px`,
      backgroundColor: flipVertical ? shapeFill : backgroundColor,
      zIndex: 10,

      '& svg': {
        position: 'absolute',
        top: '0',
        display: 'block',
        width: '100%',
        height: `${height}px`,
        transform: transform(flipHorizontal, flipVertical),
      },
    },
  };
  return (
    <Box component="div" sx={classes.waveContainer}>
      <Box component="div" sx={classes.wave}>
        <Box
          component="svg"
          fill="none"
          width={width}
          height={height}
          viewBox="0 0 1440 40"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg">
          <Box
            component="path"
            d="M5.79799e-06 16.3212C5.79799e-06 16.3212 230.115 -20.4015 720 16.3212C1290.24 59.0674 1440 9.49196 1440 9.49196L1440 40L3.49692e-06 40.0001L5.79799e-06 16.3212Z"
            sx={{ fill: flipVertical ? backgroundColor : shapeFill }}
          />
        </Box>
      </Box>
    </Box>
  );
}

WaveDivider.defaultProps = {
  position: POSITION.RELATIVE,
  shapeFill: '#000000',
  backgroundColor: 'transparent',
  flipVertical: false,
};

export default WaveDivider;
