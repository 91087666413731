import { AccordionLabel, AccordionPanel } from '@care/react-component-lib';
import { Accordion, Box } from '@mui/material';
import React, { FC } from 'react';
import { AmplitudeLogger } from '@/utilities/analyticsHelper';
import { EventNames } from '@/constants';
import BenefitDetails from '../BenefitDetails/index';
import { Benefit } from '../../types/types';

interface BenefitsAccordionsProps {
  benefits: Benefit[];
  logger: AmplitudeLogger;
}

const BenefitsAccordions: FC<BenefitsAccordionsProps> = ({ benefits, logger }) => {
  const accordionClick = (linkText: string) => {
    logger({
      name: EventNames.LINK_INTERACTED,
      data: {
        link_text: `${linkText} clicked`,
      },
    });
  };

  return (
    <Box
      component="div"
      data-testid="BenefitsAccordions"
      sx={(theme) => ({
        '& .MuiAccordionSummary-root': {
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          height: 'auto',
          alignItems: 'flex-start',
          paddingTop: 3,
          paddingBottom: 3,
        },

        '& .MuiAccordion-root:last-of-type .MuiAccordionSummary-root': {
          borderBottom: 'none',
        },

        '& .MuiAccordionSummary-content': {
          margin: 0,
          flexDirection: 'column',
        },

        '& .accordion-label-primary p': {
          fontWeight: 'bold',
          fontSize: '1.25rem',
          lineHeight: '21px',
        },
        ' .MuiAccordionSummary-content > .MuiTypography-body2': {
          fontSize: '20px',
          lineHeight: '21px',
          fontWeight: 'bold',
        },
        ' .MuiAccordionSummary-content > .MuiTypography-body3': {
          marginLeft: 0,
          fontSize: '14px',
          lineHeight: '22px',
        },
      })}>
      {benefits.map((benefit) => (
        <Accordion key={benefit.title} elevation={0} onChange={() => accordionClick(benefit.title)}>
          <AccordionLabel primaryLabel={benefit.title} secondaryLabel={benefit.headline} />
          <AccordionPanel noPadding>
            <BenefitDetails benefit={benefit} />
          </AccordionPanel>
        </Accordion>
      ))}
    </Box>
  );
};

export default BenefitsAccordions;
