import { Box, Container, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';
import WaveDivider, {
  POSITION,
} from '@/components/pages/WelcomePage/components/WaveDivider/WaveDivider';
import useResponsive from '@/components/hooks/useResponsive';
import { AmplitudeLogger } from '@/utilities/analyticsHelper';
import { BENEFITS_COPY } from '../../data/text';
import { BENEFITS } from '../../data/benefits';
import BenefitsAccordions from '../BenefitsAccordions/index';
import BenefitsTabs from '../BenefitsTabs/index';

const { HEADER, DISCLAIMER } = BENEFITS_COPY;

interface BenefitsSectionProps {
  logger: AmplitudeLogger;
}

const BenefitsSection: FC<BenefitsSectionProps> = ({ logger }) => {
  const { isDesktop } = useResponsive();
  const careTheme = useTheme();

  return (
    <Box
      component="section"
      data-testid="BenefitsSection"
      sx={(theme) => ({
        backgroundColor: theme.palette.care.white,
        position: 'relative',
      })}>
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: { xs: 7, md: 8 },
          paddingBottom: { md: 10 },
        }}>
        <Typography
          variant="serifHeadline3"
          component="h2"
          sx={(theme) => ({
            textAlign: 'center',
            marginBottom: { xs: 2, md: 6 },
            paddingLeft: 3,
            paddingRight: 3,
            fontSize: { md: theme.typography.serifDisplay3.fontSize },
            lineHeight: { md: theme.typography.serifDisplay3.lineHeight },
          })}>
          {HEADER}
        </Typography>

        {isDesktop ? (
          <BenefitsTabs benefits={BENEFITS} logger={logger} />
        ) : (
          <>
            <Typography
              variant="disclaimer2"
              color="secondary"
              sx={{
                textAlign: 'center',
                marginBottom: 2,
              }}>
              {DISCLAIMER}
            </Typography>
            <BenefitsAccordions benefits={BENEFITS} logger={logger} />
          </>
        )}
      </Container>

      {isDesktop && (
        <WaveDivider
          position={POSITION.ABSOLUTE}
          backgroundColor="transparent"
          shapeFill={careTheme.palette.care.blue[100]}
        />
      )}
    </Box>
  );
};

export default BenefitsSection;
