import React, { FC } from 'react';
import { Box } from '@mui/material';
import { SxClassProps } from '@/types/global';

const classes: SxClassProps = {
  logoPrimary: {
    maxWidth: '100px',
  },
  logoSecondary: (theme) => ({
    maxWidth: { xs: '90px', md: '142px' },
    '& path': {
      fill: theme.palette.care.navy[800],
    },
  }),
};

interface CareLogoProps {
  variant?: 'primary' | 'secondary';
}

const CareLogo: FC<CareLogoProps> = ({ variant = 'primary' }) => {
  const logoClass = variant === 'secondary' ? classes.logoSecondary : classes.logoPrimary;

  // todo: add blue Care for Business logo to shared library
  return (
    <Box
      component="svg"
      data-testid="careAltLogo"
      width="100%"
      viewBox="0 0 100 40"
      fill="none"
      sx={logoClass}>
      <path
        d="M7.11685 32.681H5.77924V31.5903H7.11685V30.9793C7.11685 29.3259 8.05331 28.384 9.45736 28.384C9.92496 28.384 10.36 28.467 10.6784 28.6653L10.3612 29.5912C10.1606 29.4759 9.92622 29.409 9.6429 29.409C8.82303 29.409 8.37173 29.9704 8.37173 30.9793V31.5903H10.0102V32.681H8.37173V39.5732H7.11811V32.681H7.11685Z"
        fill="#EF5844"
      />
      <path
        d="M11.028 35.574C11.028 33.2601 12.5662 31.3923 15.0233 31.3923C17.4804 31.3923 19.0185 33.2601 19.0185 35.574C19.0185 37.8879 17.4804 39.7718 15.0233 39.7718C12.5662 39.7718 11.028 37.8879 11.028 35.574ZM17.6985 35.574C17.6985 33.9541 16.7457 32.4991 15.0233 32.4991C13.3008 32.4991 12.3317 33.9541 12.3317 35.574C12.3317 37.1939 13.302 38.665 15.0233 38.665C16.7445 38.665 17.6985 37.21 17.6985 35.574Z"
        fill="#EF5844"
      />
      <path
        d="M21.0599 31.5893H22.3135V32.8783C22.9654 32.0355 23.9019 31.4232 25.0051 31.4232V32.6961C24.8546 32.6626 24.7042 32.6465 24.5036 32.6465C23.7351 32.6465 22.6808 33.2749 22.3135 33.9194V39.5722H21.0599V31.5893Z"
        fill="#EF5844"
      />
      <path
        d="M30.9397 28.5479H35.9053C37.8446 28.5479 39.0318 29.7042 39.0318 31.3576C39.0318 32.7457 38.0953 33.6715 37.0761 33.8872C38.2633 34.0694 39.2499 35.2753 39.2499 36.5977C39.2499 38.3664 38.0627 39.5723 36.0231 39.5723H30.9409V28.5479H30.9397ZM35.6708 33.3245C36.9245 33.3245 37.5939 32.5648 37.5939 31.5559C37.5939 30.547 36.9257 29.7712 35.6708 29.7712H32.3274V33.3245H35.6708ZM35.7373 38.349C37.041 38.349 37.8108 37.6215 37.8108 36.4478C37.8108 35.4389 37.1087 34.5465 35.7373 34.5465H32.3262V38.3478H35.7373V38.349Z"
        fill="#EF5844"
      />
      <path
        d="M46.8553 38.4502C46.2372 39.1443 45.1829 39.7727 43.9795 39.7727C42.2909 39.7727 41.4045 38.9621 41.4045 37.227V31.5902H42.6582V36.8465C42.6582 38.2519 43.3777 38.6647 44.4634 38.6647C45.45 38.6647 46.3864 38.1032 46.854 37.4575V31.5902H48.1076V39.5731H46.854V38.449L46.8553 38.4502Z"
        fill="#EF5844"
      />
      <path
        d="M50.5663 37.6209C51.1016 38.232 52.1208 38.7612 53.2252 38.7612C54.4625 38.7612 55.1307 38.2159 55.1307 37.4388C55.1307 36.5625 54.1441 36.2824 53.0572 36.0172C51.6858 35.7036 50.1313 35.3392 50.1313 33.6871C50.1313 32.4477 51.2182 31.3893 53.14 31.3893C54.5114 31.3893 55.4805 31.9012 56.1323 32.5456L55.5469 33.4045C55.0618 32.8257 54.1754 32.3969 53.14 32.3969C52.0368 32.3969 51.351 32.8927 51.351 33.6041C51.351 34.3812 52.2699 34.6291 53.3242 34.8769C54.7283 35.1905 56.3505 35.5871 56.3505 37.3557C56.3505 38.6943 55.2636 39.7688 53.1738 39.7688C51.8525 39.7688 50.733 39.3722 49.9144 38.5121L50.5663 37.6197V37.6209Z"
        fill="#EF5844"
      />
      <path
        d="M58.1727 29.4085C58.1727 28.9462 58.5576 28.5819 59.0089 28.5819C59.4602 28.5819 59.8614 28.945 59.8614 29.4085C59.8614 29.8721 59.4765 30.2513 59.0089 30.2513C58.5413 30.2513 58.1727 29.8708 58.1727 29.4085ZM58.3896 31.5899H59.6432V39.5728H58.3896V31.5899Z"
        fill="#EF5844"
      />
      <path
        d="M67.6026 34.3507C67.6026 32.9453 66.883 32.4991 65.7974 32.4991C64.8108 32.4991 63.8743 33.094 63.4067 33.7385V39.5735H62.1531V31.5906H63.4067V32.7482C63.9746 32.0876 65.079 31.3923 66.2825 31.3923C67.9711 31.3923 68.8574 32.2351 68.8574 33.9702V39.5735H67.6038V34.3507H67.6026Z"
        fill="#EF5844"
      />
      <path
        d="M74.8736 31.3923C77.3144 31.3923 78.7523 33.2762 78.7523 35.6732V35.9867H72.2159C72.3162 37.4913 73.3855 38.7468 75.1256 38.7468C76.0445 38.7468 76.9809 38.3837 77.6165 37.7392L78.2182 38.5485C77.4159 39.3418 76.329 39.7718 75.0077 39.7718C72.6171 39.7718 70.8946 38.0689 70.8946 35.574C70.8946 33.2601 72.5669 31.3923 74.8736 31.3923ZM72.2159 35.0782H77.5162C77.4999 33.8884 76.6976 32.4173 74.8585 32.4173C73.1361 32.4173 72.2836 33.855 72.2172 35.0782H72.2159Z"
        fill="#EF5844"
      />
      <path
        d="M80.7237 37.6209C81.259 38.232 82.2782 38.7612 83.3813 38.7612C84.6187 38.7612 85.2868 38.2159 85.2868 37.4388C85.2868 36.5625 84.3003 36.2824 83.2134 36.0172C81.8419 35.7036 80.2874 35.3392 80.2874 33.6871C80.2874 32.4477 81.3743 31.3893 83.2961 31.3893C84.6676 31.3893 85.6366 31.9012 86.2885 32.5456L85.703 33.4045C85.2179 32.8257 84.3316 32.3969 83.2961 32.3969C82.1929 32.3969 81.5072 32.8927 81.5072 33.6041C81.5072 34.3812 82.4261 34.6291 83.4804 34.8769C84.8844 35.1905 86.5066 35.5871 86.5066 37.3557C86.5066 38.6943 85.4197 39.7688 83.3299 39.7688C82.0086 39.7688 80.8892 39.3722 80.0705 38.5121L80.7224 37.6197L80.7237 37.6209Z"
        fill="#EF5844"
      />
      <path
        d="M88.498 37.6209C89.0333 38.232 90.0525 38.7612 91.1569 38.7612C92.3942 38.7612 93.0624 38.2159 93.0624 37.4388C93.0624 36.5625 92.0758 36.2824 90.9889 36.0172C89.6175 35.7036 88.063 35.3392 88.063 33.6871C88.063 32.4477 89.1499 31.3893 91.0717 31.3893C92.4431 31.3893 93.4122 31.9012 94.064 32.5456L93.4786 33.4045C92.9935 32.8257 92.1071 32.3969 91.0717 32.3969C89.9685 32.3969 89.2827 32.8927 89.2827 33.6041C89.2827 34.3812 90.2016 34.6291 91.2559 34.8769C92.66 35.1905 94.2822 35.5871 94.2822 37.3557C94.2822 38.6943 93.1953 39.7688 91.1055 39.7688C89.7842 39.7688 88.6647 39.3722 87.8461 38.5121L88.498 37.6197V37.6209Z"
        fill="#EF5844"
      />
      <path
        d="M3.94275 6.36124C1.28508 10.1959 1.38787 15.3382 4.04429 19.1307C5.7442 21.5587 8.35549 23.3025 11.2325 24.0647C13.3023 24.6125 15.56 24.6683 17.6385 24.0461C19.1517 23.5937 20.9393 22.5898 21.8983 21.462C22.1227 21.198 22.3446 20.8361 22.3584 20.4841C22.3697 20.2003 22.1603 19.971 21.8795 19.9313C21.5373 19.883 21.1499 20.1557 20.9681 20.2623C20.7977 20.3626 20.4303 20.5733 20.1533 20.7022C19.7095 20.9092 19.2532 21.0716 18.7831 21.2067C15.6039 22.1251 12.3307 20.1668 10.8251 17.429C10.6897 17.1824 10.5656 16.9283 10.454 16.6705C9.89995 15.3914 9.63418 14.0046 9.5389 12.6164C9.42733 10.9842 9.55144 9.32586 10.0316 7.76051C10.5431 6.09229 11.467 4.57279 12.7833 3.40653C13.4778 2.79055 13.9692 2.4745 14.7715 1.92793C15.0235 1.7569 15.2692 1.64287 15.2128 1.37392C15.1413 1.03309 14.8041 0.988473 14.4556 1.00211C14.2914 1.0083 13.7197 1.06655 13.657 1.07275C11.1548 1.3355 7.90794 2.33693 5.62636 4.43522C4.99704 5.01402 4.43041 5.65974 3.944 6.36124H3.94275Z"
        fill="#EF5844"
      />
      <path
        d="M77.4377 12.6831C77.4591 12.1713 78.0044 12.0845 78.4018 12.0833C79.1138 12.0808 79.6165 12.1254 80.4251 11.8131C81.6737 11.3309 82.5851 10.1783 82.7719 8.89554C83.0088 7.26574 82.1025 5.60495 80.6583 4.97038C80.6169 4.95179 80.5292 4.91337 80.4966 4.89974C79.184 4.35688 77.622 4.65186 76.5201 5.56033C76.1114 5.89745 75.7742 6.3176 75.5222 6.78113C74.7074 8.27832 74.4203 9.99612 74.4968 11.6829C74.6785 15.6589 76.5439 19.2469 80.5204 20.5718C81.8016 20.9994 83.1618 20.9647 83.9791 20.7578C84.5771 20.6066 85.135 20.3463 85.6602 20.0265C86.178 19.7117 86.5779 19.4155 87.2059 18.9693C87.5143 18.7499 87.9481 18.6161 88.2113 19.0201C88.4821 19.4366 88.1223 20.0711 87.8779 20.4206C87.5219 20.9313 87.068 21.3675 86.613 21.7927C85.2591 23.0618 83.5842 23.824 81.7778 24.2367C80.395 24.5528 78.7553 24.5416 77.439 24.3607C74.3062 23.9294 71.3464 22.1843 69.4246 19.7006C68.6098 18.6471 67.9792 17.4548 67.5743 16.1894C66.5526 12.9892 67.0352 9.45946 68.8342 6.62373C69.4346 5.67684 70.1718 4.81422 71.0167 4.07059C72.2227 3.00843 74.5995 1.00309 79.4498 1.00309C84.9331 1.00309 87.7914 3.64423 88.6777 6.21721C89.2468 7.86932 89.1516 9.73956 88.3793 11.3235C87.5068 13.1132 85.7994 14.567 82.3419 14.5459C80.8827 14.5372 79.6103 14.365 78.3178 13.801C77.8063 13.5767 77.4152 13.2359 77.4377 12.6831Z"
        fill="#EF5844"
      />
      <path
        d="M57.8281 20.4012C57.8281 20.8573 57.8281 21.5922 57.8306 22.0483C57.8319 22.8279 57.9924 23.5343 58.8724 23.8219C59.0441 23.8789 59.1733 23.988 59.1444 24.1838C59.1106 24.4056 58.9351 24.4738 58.7332 24.4862C58.5878 24.4949 58.4412 24.4912 58.2957 24.4912C55.6042 24.4912 52.5379 24.4912 49.8463 24.4912C49.6796 24.4912 49.5116 24.4936 49.3461 24.4763C49.1731 24.4577 49.0102 24.3945 48.9763 24.2011C48.9412 24.0066 49.0566 23.8901 49.2358 23.8355C50.0895 23.574 50.2901 22.9109 50.2901 22.1425C50.2939 16.714 50.2976 11.0066 50.2851 5.57803C50.2839 4.85423 50.1309 4.17008 49.2484 3.96063C49.0779 3.91973 48.9513 3.79579 48.9763 3.59748C49.0102 3.34465 49.2057 3.2678 49.4264 3.25045C50.2613 3.186 51.4497 3.00505 52.2583 2.80799C53.9206 2.40271 55.514 1.80036 57.0898 1.1472C57.7115 0.889411 57.8444 0.9551 57.8457 1.59587C57.8457 2.07551 57.8494 6.87816 57.8494 7.35904C57.8494 8.78062 57.8294 19.9203 57.8294 20.3999L57.8281 20.4012Z"
        fill="#EF5844"
      />
      <path
        d="M19.334 8.43944C21.4111 8.43944 23.0949 6.77476 23.0949 4.72127C23.0949 2.66777 21.4111 1.00309 19.334 1.00309C17.257 1.00309 15.5732 2.66777 15.5732 4.72127C15.5732 6.77476 17.257 8.43944 19.334 8.43944Z"
        fill="#EF5844"
      />
      <path
        d="M31.7447 8.43943C33.8218 8.43943 35.5056 6.77474 35.5056 4.72125C35.5056 2.66776 33.8218 1.00307 31.7447 1.00307C29.6676 1.00307 27.9838 2.66776 27.9838 4.72125C27.9838 6.77474 29.6676 8.43943 31.7447 8.43943Z"
        fill="#EF5844"
      />
      <path
        d="M62.8351 8.43944C64.9122 8.43944 66.5959 6.77476 66.5959 4.72127C66.5959 2.66777 64.9122 1.00309 62.8351 1.00309C60.758 1.00309 59.0742 2.66777 59.0742 4.72127C59.0742 6.77476 60.758 8.43944 62.8351 8.43944Z"
        fill="#EF5844"
      />
      <path
        d="M94.3009 24.4736C96.3779 24.4736 98.0617 22.8089 98.0617 20.7554C98.0617 18.7019 96.3779 17.0372 94.3009 17.0372C92.2238 17.0372 90.54 18.7019 90.54 20.7554C90.54 22.8089 92.2238 24.4736 94.3009 24.4736Z"
        fill="#EF5844"
      />
      <path
        d="M44.7311 14.1535C44.7311 16.6621 44.7236 19.3367 44.7211 21.8452C44.7211 22.7239 44.7838 23.5605 45.8431 23.8691C46.0048 23.9162 46.0637 24.055 46.0386 24.2124C46.0173 24.3463 45.917 24.4343 45.7879 24.4566C45.6249 24.4839 45.457 24.4938 45.2915 24.4938C42.8093 24.4963 40.5089 24.4665 38.0268 24.4888C37.4614 24.4938 37.2733 24.3352 37.2771 23.7502C37.2796 23.4676 37.2859 23.2197 37.2821 22.8875C37.2796 22.6954 37.2696 22.4661 37.0527 22.3893C36.8396 22.3137 36.6992 22.4947 36.5625 22.6322C35.868 23.3238 35.1021 23.7811 34.1619 24.1294C31.2071 25.2226 26.9134 23.7043 25.6034 21.0904C24.4175 18.7219 25.0882 16.1118 27.2532 14.446C28.6798 13.3479 30.2581 12.6415 31.8803 11.7702C33.1941 11.0166 34.5154 10.2792 35.6725 9.28769C38.1258 7.18692 38.3138 4.28674 36.1325 1.90463C35.8216 1.56503 35.9006 1.19817 36.3544 1.1424C39.0234 0.8152 41.5845 0.942858 43.5891 3.05602C44.518 4.03514 44.7437 5.17538 44.7311 6.56722C44.7086 9.09682 44.7311 11.6252 44.7311 14.1548V14.1535ZM37.2056 15.0769C37.2056 13.9069 37.2006 12.7357 37.2081 11.5657C37.2107 11.1369 37.2483 10.9646 36.7493 11.2546C35.5985 11.9239 34.4828 12.6402 33.5062 13.54C32.0044 14.9244 31.1043 16.5852 31.0855 18.6277C31.0704 20.2873 32.0658 21.6841 33.4247 21.9159C35.2726 22.2319 37.1467 20.5649 37.1994 18.5274C37.2295 17.3784 37.2044 16.227 37.2056 15.0769Z"
        fill="#EF5844"
      />
      <path
        d="M95.4315 39.7886H95.6374V38.3276H96.1634V38.1453H94.908V38.3276H95.4315V39.7886Z"
        fill="#EF5944"
      />
      <path
        d="M97.8514 39.7886H98.0574V38.1453H97.7621L97.246 39.3969L96.7324 38.1453H96.4372V39.7886H96.6431V38.4212L97.2038 39.7886H97.2882L97.8514 38.4212V39.7886Z"
        fill="#EF5944"
      />
    </Box>
  );
};

export default CareLogo;
