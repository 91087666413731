import { useLazyQuery } from '@apollo/client';
import { FIND_CARE_GROUPS_BY_PARTIAL_NAME } from '@/components/request/GQL';
import {
  findCareGroupsByPartialName,
  findCareGroupsByPartialNameVariables,
} from '@/__generated__/findCareGroupsByPartialName';

export const useFindCareGroupName = () => {
  const [getSuggestions, { data, error }] = useLazyQuery<
    findCareGroupsByPartialName,
    findCareGroupsByPartialNameVariables
  >(FIND_CARE_GROUPS_BY_PARTIAL_NAME, {
    fetchPolicy: 'no-cache',
    context: { skipAuth: true },
  });

  return { getSuggestions, data, error };
};
