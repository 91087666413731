import React, { FC } from 'react';

interface IconPlayVideoProps {
  width?: number;
  height?: number;
}

const IconPlayVideo: FC<IconPlayVideoProps> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5488 62.8882C48.6965 62.8882 62.5976 48.9872 62.5976 31.8394C62.5976 14.6916 48.6965 0.790619 31.5488 0.790619C14.401 0.790619 0.5 14.6916 0.5 31.8394C0.5 48.9872 14.401 62.8882 31.5488 62.8882ZM23.7883 45.8647L47.7129 32.0519L23.7883 18.239L23.7883 45.8647Z"
        fill="white"
      />
    </svg>
  );
};

IconPlayVideo.defaultProps = {
  width: 64,
  height: 64,
};

export default IconPlayVideo;
